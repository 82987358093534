/*
 *
 * INSTRUCTIONS
 *
 * Copy the Legal Disclaimer and the @font-faces statements to your regular CSS file.
 * The font file(s) should be placed relative to the regular CSS file.
 *
 */

/*
 * Legal Disclaimer for Web licence
 *
 * Licence to use self-hosted webfonts for displaying dynamic text on specified website domains. Our package includes WOFF and WOFF2 font formats.
 *
 * Should the license page views limit be exceeded the license holder will be required to contact Lineto.com for an upgrade.
 *
 * It is strictly prohibited to rename the font and to download or use these fonts in any other media.
 *
 * These Web fonts are licensed exclusively for the use on the following domain(s) and their subdomains:
 *
 * trovata.io (monthly pageviews: < 1M)
 *
 */

/* complete */

@font-face {
	font-family: AkkuratLLWeb;
	src: url('./AkkuratLLWeb-Italic.woff') format('woff');
	font-style: italic;
}

@font-face {
	font-family: AkkuratLLWeb;
	src: url('./AkkuratLLWeb-Italic.woff2') format('woff2');
	font-style: italic;
}

@font-face {
	font-family: AkkuratLLWeb;
	src: url('./AkkuratLLWeb-Regular.woff') format('woff');
}

@font-face {
	font-family: AkkuratLLWeb;
	src: url('./AkkuratLLWeb-Regular.woff2') format('woff2');
}

@font-face {
	font-family: AkkuratLLWeb;
	src: url('./AkkuratLLWeb-Bold.woff') format('woff');
	font-weight: bold;
}

@font-face {
	font-family: AkkuratLLWeb;
	src: url('./AkkuratLLWeb-Bold.woff2') format('woff2');
	font-weight: bold;
}

@font-face {
	font-family: AkkuratLLWeb;
	src: url('./AkkuratLLWeb-BoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: AkkuratLLWeb;
	src: url('./AkkuratLLWeb-BoldItalic.woff2') format('woff2');
	font-weight: bold;
	font-style: italic;
}

/* subset */

@font-face {
	font-family: AkkuratLLSub;
	src: url('AkkuratLLSub-Italic.woff') format('woff');
	font-style: italic;
}

@font-face {
	font-family: AkkuratLLSub;
	src: url('./AkkuratLLSub-Italic.woff2') format('woff2');
	font-style: italic;
}

@font-face {
	font-family: AkkuratLLSub;
	src: url('./AkkuratLLSub-Regular.woff') format('woff');
}

@font-face {
	font-family: AkkuratLLSub;
	src: url('./AkkuratLLSub-Regular.woff2') format('woff2');
}

@font-face {
	font-family: AkkuratLLSub;
	src: url('./AkkuratLLSub-Bold.woff') format('woff');
	font-weight: bold;
}

@font-face {
	font-family: AkkuratLLSub;
	src: url('./AkkuratLLSub-Bold.woff2') format('woff2');
	font-weight: bold;
}

@font-face {
	font-family: AkkuratLLSub;
	src: url('./AkkuratLLSub-BoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: AkkuratLLSub;
	src: url('./AkkuratLLSub-BoldItalic.woff2') format('woff2');
	font-weight: bold;
	font-style: italic;
}
