$AkkuratLLWeb: 'AkkuratLLWeb';
$background-color: white;
$dark-primary-text: rgba(0, 0, 0, 0.88);
$mat-card-light-border: rgba(0, 0, 0, 0.16);
$mat-chip-color: rgba(224, 224, 224, 0.87);
$mat-form-field-outline-color: rgba(0, 0, 0, 0.12);
$light-text-color: rgba(0, 0, 0, 0.56);
$disabled-color: rgba(0, 0, 0, 0.38);
$bold: 600;
$input: mat-typography-level(inherit, 1.15, 400);
$trovata-mat-card: (
	border-style: solid,
	border-width: 1px,
	border-color: rgba(0, 0, 0, 0.16),
);
$primary: #0064fd;
$green: #038142;
$red: #d5255a;
$gray: #707070;
$surface: #f5f5f5;
$font-weight-bold: 700;
